import { v2Colors, getRgbaColor, breakpoints } from '@web-for-marketing/react-ui';

export const ResourceBoxesStyle = {
    container: {
        background: v2Colors.core.cloud,
    },
    containerNoColor: {
        background: v2Colors.core.snow,
    },
    media: {
        minHeight: '100%',
        objectFit: 'fill',
        maxWidth: '100%',
        width: '100%',
    },
    card: {
        position: 'relative',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        borderRadius: 10,
        backgroundColor: v2Colors.core.snow,
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${v2Colors.core.smoke}`,
        boxShadow: `0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 0 0 ${getRgbaColor(v2Colors.core.slate, 0.1)}`,
        transition: 'transform ease 0.75s, box-shadow ease 500ms',
        '&:hover': {
            boxShadow: `0 0 6px ${getRgbaColor(v2Colors.core.slate, 0.1)}, 0 10px 10px ${getRgbaColor(
                v2Colors.core.slate,
                0.1
            )}`,
            transition: 'transform ease 750ms, box-shadow ease 750ms',
            transform: 'translateY(-10px)',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            margin: '0 auto',
            maxWidth: 450,
            height: 'auto',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            '&:hover': {
                transform: 'none',
            },
        },
    },
    reverseCard: {
        flexDirection: 'row-reverse',
    },
    titles: {
        color: v2Colors.core.geotab,
        maxWidth: '72rem',
        margin: '0 auto 1rem 0',
    },
    cardContainer: {
        minHeight: 360,
        width: '100%',
        marginBottom: '3rem',
        boxSizing: 'border-box',
        [`@media (max-width: ${breakpoints.md})`]: {
            minHeight: 'initial',
            maxHeight: 'initial',
            height: 'auto',
            width: '100%',
            marginBottom: '3rem',
            boxSizing: 'border-box',
        },
    },
    cardImage: {
        maxWidth: '48.718%',
        height: '100%',
        position: 'absolute',
        [`@media (max-width: ${breakpoints.md})`]: {
            maxHeight: '50%',
            minWidth: '100%',
            position: 'static',
            overflow: 'hidden',
        },
    },
    cardContent: {
        padding: '3rem 6rem 4rem 7rem',
        width: '50%',
        '& > * ': {
            width: '100%',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            padding: '2rem 3rem 2rem 2rem',
            width: '100%',
        },
    },
    linkText: {
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            bottom: '1.5rem',
        },
    },
    bodyText: {
        paddingBottom: '9.4rem',
    },
    noButtonBody: {
        paddingBottom: '4rem',
    },
    verticalContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        zIndex: 3,
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingTop: '4rem',
        },
    },
    arrowContainer: {
        display: 'flex',
        marginBottom: '1rem',
    },
    cardTitle: {
        fontWeight: '400',
    },
    cardLink: {
        marginTop: '2rem',
    },
    cardSubTitle: {
        marginBottom: '1rem',
        fontWeight: '500',
    },
} as const;
