import { v2Colors, getRgbaColor, breakpoints, space } from '@web-for-marketing/react-ui';
interface CallToActionBannerStyleProps {
    imagePath?: string;
    backgroundPosition?: {
        x: number;
        y: number;
    };
    customGradient?: string;
}

export const callToActionBannerStyle = {
    titles: {
        marginBottom: 'unset',
    },
    titlesPadding: {
        marginBottom: '2rem',
    },
    label: {
        textTransform: 'none',
    },
    parentContainer: {
        alignItems: 'flex-start',
        width: '100%',
    },
    buttonContainer: {
        maxWidth: '75rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            '& > *:not(:last-child)': {
                marginBottom: '2rem !important',
            },
        },
    },
    buttonItem: {
        marginRight: '2rem',
        '& > a': {
            display: 'flex',
        },
        '& > button': {
            display: 'flex',
            width: '100%',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            width: '100%',
            marginRight: '0',
        },
    },
    noPaddingDescription: {
        marginBottom: 'unset !important',
    },
    bannerContainer: {
        flex: '1 0 auto',
        '& > *:not(:last-child)': {
            marginBottom: '3rem',
        },
        '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
            flexDirection: 'row !important' as 'row',
        },
    },
    smallerBanner: {
        paddingTop: '5rem',
        paddingBottom: '8rem',
        [`@media (max-width: ${breakpoints.md})`]: {
            padding: `4rem ${space.containerOuterPadding} !important`,
        },
    },
    bannerStandard: {
        paddingTop: '8rem !important',
        paddingBottom: '8rem !important',
    },
    containedButton: {
        color: v2Colors.core.innovation,
        backgroundColor: v2Colors.core.snow,
        borderColor: v2Colors.core.snow,
        transition: 'color 500ms ease',
        '&:hover': {
            color: v2Colors.core.geotab,
            backgroundColor: v2Colors.core.snow,
            transition: 'color 500ms ease',
        },
    },
    titleContainer: {
        marginBottom: '1rem',
    },
    titleTypography: {
        marginBottom: '0',
    },
    titleImage: {
        maxWidth: '35rem',
        marginBottom: '3rem',
        maxHeight: '20rem',
        width: 'auto',
    },
    descriptions: {
        [`@media (max-width: ${breakpoints.md})`]: {
            marginBottom: '3rem !important',
        },
    },
    defaultBg: (zIndex?: number) => ({
        zIndex: zIndex,
        background: `linear-gradient(${getRgbaColor(v2Colors.core.geotab, 0.95)}, ${getRgbaColor(
            v2Colors.core.innovation,
            0.95
        )})`,
    }),
    whiteBg: (zIndex?: number) => ({
        borderBottom: '1px solid #DFE3EA',
        background: v2Colors.core.snow,
        zIndex: zIndex,
    }),
    gradientImageBg: ({ customGradient, backgroundPosition, imagePath }: CallToActionBannerStyleProps) => {
        let backgroundResult: string[] | string = [];
        backgroundResult[0] = customGradient
            ? `${customGradient},`
            : `linear-gradient(${getRgbaColor(v2Colors.core.geotab, 0.95)}, ${getRgbaColor(
                v2Colors.core.innovation,
                0.95
            )}),`;
        backgroundResult[1] = `url('${imagePath}') ${
            backgroundPosition ? `${backgroundPosition.x}% ${backgroundPosition.y}%` : 'center'
        } / cover no-repeat`;
        backgroundResult = backgroundResult.join(' ');

        return {
            background: backgroundResult,
        };
    },
    alignedText: (align: string) => {
        let alignment: 'center' | 'left' | 'right' = 'center';

        if (align === 'flex-start') {
            alignment = 'left';
        } else if (align === 'flex-end') {
            alignment = 'right';
        }

        return {
            textAlign: alignment,
        };
    },
} as const;
