import React from 'react';
import { Grid } from '@mui/material';
import { ChildContainer } from '../GeotabGridComponents';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { styles as classes } from './styleObjects/EventTile';
import { Picture } from '../Picture';
import { CMSLink } from '@components/CMSLink';
import { DateIcon } from '../IconComponents/DateIcon';
import { LocationIcon } from '../IconComponents/LocationIcon';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { Event } from '@models/section/eventsSection';

type EventTileProps = Event & {
    lazyLoad: boolean;
    order: number;
    className?: string;
    shouldIncreaseHeadingLevel: boolean;
};

export function EventTile({
    image,
    buttons,
    dates,
    location,
    title,
    description,
    order,
    lazyLoad,
    className,
    shouldIncreaseHeadingLevel,
}: EventTileProps): JSX.Element {
    return (
        <ChildContainer
            data-testid={`eventTile-${order || 0}`}
            direction='row'
            lazyLoad={lazyLoad}
            className={className}
        >
            <Grid item xs={12} md={6} css={classes.mediaContainer}>
                {image.imagePath ? (
                    <Picture
                        imagePath={image.imagePath}
                        mobilePath={image.mobilePath}
                        alt={image.imageAlt}
                        mobileAlt={image.mobileAlt}
                        css={classes.imageMedia}
                        lazyLoad={lazyLoad}
                        aria-hidden='true'
                    />
                ) : null}
            </Grid>
            <Grid container item xs={12} md={6} direction='column' css={classes.contentContainer}>
                <Heading
                    variant='h3'
                    weight='regular'
                    component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                    data-testid='eventTileTitle'
                >
                    <ParseAbbreviations content={title} />
                </Heading>
                <Grid container direction='row' css={classes.infoContainer}>
                    <Grid item xs={12} md={6}>
                        <Text variant='body1' color='primary'>
                            <DateIcon css={classes.icon} />
                            {dates}
                        </Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Text variant='body1' color='primary'>
                            <LocationIcon css={classes.icon} />
                            {location}
                        </Text>
                    </Grid>
                </Grid>
                <Text variant='body1' color='tertiary' css={{ marginBottom: '3rem' }}>
                    <ParseAbbreviations content={description} />
                </Text>
                {buttons.length ? (
                    <Grid container direction='row'>
                        {buttons.map(
                            (
                                {
                                    buttonText,
                                    buttonLinkPath,
                                    buttonType,
                                    buttonTarget,
                                    buttonClassName,
                                    ariaLabel,
                                    isPopout,
                                    popoutDisplayMode,
                                    popoutBackgroundImage,
                                    popoutCustomSection,
                                    params,
                                },
                                index
                            ) =>
                                isPopout ? (
                                    <PopoutButton
                                        key={`${index}-${buttonText}-${popoutCustomSection}`}
                                        text={buttonText}
                                        variant={buttonType || 'contained'}
                                        customSectionKey={popoutCustomSection}
                                        displayMode={popoutDisplayMode}
                                        backgroundImagePath={popoutBackgroundImage}
                                        params={params}
                                        aria-label={ariaLabel}
                                        css={classes.buttonItem}
                                        className={buttonClassName}
                                    />
                                ) : (
                                    <CMSLink
                                        key={`${index}-${buttonText}-${popoutCustomSection}`}
                                        variant={buttonType || 'contained'}
                                        href={buttonLinkPath}
                                        target={getLinkTargetValue(buttonTarget)}
                                        css={classes.buttonItem}
                                        aria-label={ariaLabel}
                                        type='button'
                                        buttonClassName={buttonClassName}
                                        data-testid='eventButtonId'
                                    >
                                        {buttonText}
                                    </CMSLink>
                                )
                        )}
                    </Grid>
                ) : null}
            </Grid>
        </ChildContainer>
    );
}

EventTile.defaultProps = {
    title: '',
    description: '',
    image: {
        imagePath: '',
        mobilePath: '',
        imageAlt: '',
    },
    buttons: [],
    dates: '',
    location: '',
};
