import React from 'react';
import { ForwardIcon, V2Button } from '@web-for-marketing/react-ui';
import { LinkListWidget } from '@models/widget';
import { Stack } from '@mui/material';

interface LinkListProps {
    widget: LinkListWidget;
    className?: string;
}

export function LinkList({ widget, className }: LinkListProps): JSX.Element {
    return (
        <Stack spacing='0.8rem' css={{ width: '100%' }} className={className} data-testid='link-list'>
            {widget.content.links.map((link, index) => (
                <div key={index}>
                    <V2Button
                        variant='link'
                        size='small'
                        component='a'
                        href={link.url}
                        target={link.shouldOpenNewTab ? '_blank' : '_self'}
                        rel={link.shouldOpenNewTab ? 'noopener noreferrer' : undefined}
                        rightIcon={<ForwardIcon />}
                    >
                        {link.text}
                    </V2Button>
                </div>
            ))}
        </Stack>
    );
}
