import React from 'react';
import { GridContainer } from '../GeotabGridComponents';
import { scrollingBannerStyles as classes } from './styleObjects/ScrollingBanner';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { Picture } from '../Picture';
import { Heading, LazyLoadContainer, Text } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { Banner } from '@models/section/scrollingBannerSection';
import { Grid } from '@mui/material';
import scrollingBanner1 from '@images/banners/sample-scrolling-banner1.png';
import scrollingBanner2 from '@images/banners/sample-scrolling-banner2.svg';
import { removeSubstringByDelimiter } from '@helpers/string';

interface ScrollingBannerSlideProps {
    lazyLoad: boolean;
    banner: Banner;
}

export function ScrollingBannerSlide({ lazyLoad, banner }: ScrollingBannerSlideProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const { body, image, button, title } = banner;
    const scrollingBanner1WithoutHash = removeSubstringByDelimiter(scrollingBanner1, 1, '.');
    const scrollingBanner2WithoutHash = removeSubstringByDelimiter(scrollingBanner2, 1, '.');

    if (image.imagePath === scrollingBanner1WithoutHash) {
        image.imagePath = scrollingBanner1;
    } else if (image.imagePath === scrollingBanner2WithoutHash) {
        image.imagePath = scrollingBanner2;
    }

    return (
        <GridContainer css={classes.gridContainer} direction='row' wrap='nowrap' justifyContent='flex-end'>
            {isMobile ? null : (
                <LazyLoadContainer
                    component={Grid}
                    lazyload={lazyLoad}
                    item
                    xs={12}
                    md={4}
                    css={[classes.leftImageContainer, classes.leftImageGradient]}
                >
                    <Picture
                        css={classes.fullImage}
                        alt={image.imageAlt}
                        imagePath={image.imagePath}
                        title={image.imageAlt || ''}
                        lazyLoad={lazyLoad}
                    />
                </LazyLoadContainer>
            )}
            <Grid container item xs={12} md={5} direction='column' alignItems='flex-start' justifyContent='center'>
                {title ? (
                    <Heading variant='h4' component='h2' color='primary'>
                        {title}
                    </Heading>
                ) : null}
                <Text variant='body1' color='primary'>
                    {body}
                </Text>
            </Grid>
            {button.buttonText ? (
                <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    direction='column'
                    alignItems='flex-end'
                    justifyContent='center'
                    css={classes.linkContainer}
                >
                    {button.isPopout ? (
                        <PopoutButton
                            tabIndex={-1}
                            text={button.buttonText}
                            variant={button.buttonType || 'outlined'}
                            customSectionKey={button.popoutCustomSection}
                            displayMode={button.popoutDisplayMode}
                            backgroundImagePath={button.popoutBackgroundImage}
                            params={button.params}
                            aria-label={button.ariaLabel}
                            classes={{
                                root: button.buttonClassName ? button.buttonClassName : '',
                            }}
                        />
                    ) : (
                        <CMSLink
                            tabIndex={-1}
                            aria-label={button.ariaLabel}
                            variant={button.buttonType || 'outlined'}
                            href={button.buttonLinkPath}
                            target={getLinkTargetValue(button.buttonTarget)}
                            type='button'
                            buttonClassName={button.buttonClassName}
                        >
                            {button.buttonText}
                        </CMSLink>
                    )}
                </Grid>
            ) : null}
        </GridContainer>
    );
}
