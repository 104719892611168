import React from 'react';
import { SectionWidget } from '@models/widget';
import { StatsHighlights } from './Widgets/StatsHighlights';
import { LinkList } from './Widgets/LinkList';

interface WidgetProps {
    widget: SectionWidget;
    className?: string;
}

export function Widget({ widget, className }: WidgetProps): JSX.Element | null {
    function getWidget(widget: SectionWidget): JSX.Element | null {
        switch (widget.type) {
            case 'stats-highlights':
                return <StatsHighlights widget={widget} className={className} />;
            case 'link-list':
                return <LinkList widget={widget} className={className} />;
            default:
                return null;
        }
    }

    return getWidget(widget);
}
