import { SlugEntity } from './slugEntity';
import { Tag, TagPreview } from './tag';

export interface Video extends SlugEntity {
    canonical: string | null;
    description: string | null;
    imagePath: string | null;
    metaDescription: string | null;
    title: string;
    videoKey: string;
    tags?: Tag[];
    videoType: VideoType;
}

export type NullableVideo = Video | null;

export interface SectionVideo {
    sectionId: number;
    videoId: number;
}

export enum VideoType {
    YouTube = 0,
    Vidyard = 1,
}

export interface VideoFormData {
    id?: number;
    title: string;
    description: string | null;
    metaDescription: string | null;
    imagePath: string | null;
    videoType: VideoType;
    youtubeUrl: string;
    vidyardUrl: string;
    slug: string;
    canonical: string | null;
    redirectUrl: string | null;
    language: number;
    status: number;
    publishedDateUtc: string | null;
    tags?: TagPreview[];
    breadcrumbDisplayName: string | null;
}

export interface VideoPageData {
    video: NullableVideo;
    otherVideos: Video[] | null;
    relatedVideos: Video[] | null;
}

export type NullableVideoPageData = VideoPageData | null;
