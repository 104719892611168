import React from 'react';
import { Stack } from '@mui/material';
import { Text, breakpoints, v2Colors } from '@web-for-marketing/react-ui';
import { StatsHighlightsWidget } from '@models/widget';

interface StatsHighlightsProps {
    widget: StatsHighlightsWidget;
    className?: string;
}

const classes = {
    container: {
        display: 'flex',
        width: '100%',
        gap: '0.8rem',
        boxSizing: 'border-box',
        flexDirection: 'column',
        [`@media (min-width: ${breakpoints.md})`]: {
            gap: '1.2rem',
            flexDirection: 'row',
        },
    },
    // a stat card should take up at least 50% of it's parent and can grow up to 100% if there is only one stat
    // on mobile it should always be 100%
    statItem: {
        flexBasis: '100%',
        minWidth: '50%',
        [`@media (min-width: ${breakpoints.md})`]: {
            flexBasis: 'auto',
        },
    },
    statCard: {
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '4px',
        padding: '1.2rem 1.6rem',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
} as const;

export function StatsHighlights({ widget, className }: StatsHighlightsProps): JSX.Element {
    return (
        <div className={className} data-testid='stats-highlights' css={classes.container}>
            {widget.content.stats.map((stat, index) => (
                <div key={index} css={classes.statItem}>
                    <div key={index} css={classes.statCard}>
                        <Stack component='p' direction='row' spacing='1.8rem' alignItems='center'>
                            <Text component='span' variant='h2' weight='bold' color='activeSecondary'>
                                {stat.unit}
                            </Text>
                            <Text component='span' variant='body3' color='secondary'>
                                {stat.description}
                            </Text>
                        </Stack>
                    </div>
                </div>
            ))}
        </div>
    );
}
