import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { LazyLoadContainer, Text, containerWidth } from '@web-for-marketing/react-ui';
import { testimonialStyles as classes } from './styleObjects/TestimonialStyles';
import { TestimonialSectionData } from '@models/section/testimonialSection';
import { Picture } from '@components/Picture';
import { DarkQuotationMark } from '@icon/DarkQuotationMark';
import { LightQuotationMark } from '@icon/LightQuotationMark';
import { Divider } from '@mui/material';
import { CarouselDotTabs } from '@components/BlogSection/BlogComponents/Home/CarouselDotTabs';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { RightChevronIcon } from '@icon/RightChevronIcon';
import { RichTextContent } from '@components/RichTextContent';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { SectionContainer } from './SectionContainer';

interface TestimonialProps {
    sectionData: TestimonialSectionData;
    lazyLoad: boolean;
}

export function Testimonial({ sectionData, lazyLoad }: TestimonialProps): JSX.Element {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = sectionData.testimonials.length;
    const showArrows = maxSteps > 1;

    if (activeStep > maxSteps - 1) {
        setActiveStep(activeStep - 1);
    }

    function handleStepChange(step: number): void {
        setActiveStep(step);
    }

    return (
        <SectionContainer maxWidth={containerWidth.lg}>
            <Grid container justifyContent='center' alignItems='center' flexWrap='nowrap'>
                {showArrows ? (
                    <button
                        css={[classes.chevronIcon, classes.leftChevronIcon, activeStep === 0 ? classes.grayIcon : '']}
                        onClick={() => {
                            handleStepChange(activeStep - 1);
                        }}
                        disabled={activeStep === 0}
                        aria-label='go to previous slide'
                    >
                        <RightChevronIcon />
                    </button>
                ) : null}
                <SwipeableViews
                    axis='x'
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    css={classes.swipeViewContainer}
                    springConfig={{
                        duration: '0.6s',
                        easeFunction: 'ease',
                        delay: '0s',
                    }}
                >
                    {sectionData.testimonials.map((testimonial, index) => {
                        const imageMode = testimonial.imagePath ? true : false;
                        return (
                            <LazyLoadContainer
                                component={Grid}
                                lazyload={lazyLoad}
                                key={`${JSON.stringify(testimonial)}-${index}`}
                                css={{ padding: '0 1rem' }}
                                container
                                justifyContent='center'
                            >
                                <Grid
                                    container
                                    data-testid='TestimonialSection'
                                    css={[
                                        classes.testimonialSectionContainer,
                                        sectionData.darkMode ? classes.darkModeBackground : classes.lightModeBackground,
                                    ]}
                                >
                                    {sectionData.testimonials && testimonial.imagePath ? (
                                        <Grid item sm={12} md={6} css={imageMode ? null : classes.hideContainer}>
                                            <Picture
                                                imagePath={testimonial.imagePath}
                                                alt={testimonial.imageAlt}
                                                css={classes.testimonialBannerImage}
                                                lazyLoad={lazyLoad}
                                                data-testid='TestimonialBanner'
                                            />
                                        </Grid>
                                    ) : null}
                                    <Grid
                                        item
                                        sm={12}
                                        md={imageMode ? 6 : 12}
                                        css={imageMode ? classes.quoteContainer : classes.noImageQuoteContainer}
                                    >
                                        {sectionData.darkMode ? (
                                            <LightQuotationMark css={classes.quotationMarkIcon} />
                                        ) : (
                                            <DarkQuotationMark css={classes.quotationMarkIcon} />
                                        )}
                                        <Grid item sm={12}>
                                            <RichTextContent
                                                variant='h4'
                                                css={classes.testimonialQuote}
                                                color={sectionData.darkMode ? 'inversePrimary' : 'primary'}
                                                content={
                                                    ParseAbbreviations({
                                                        content: testimonial.description,
                                                        stringFormat: true,
                                                    }) ?? ''
                                                }
                                                data-testid='QuoteDescription'
                                                hideLinks={index !== activeStep}
                                            />
                                        </Grid>
                                        <Divider
                                            css={[
                                                classes.quoteHorizontalDivider,
                                                sectionData.darkMode
                                                    ? classes.quoteHorizontalDividerLight
                                                    : classes.quoteHorizontalDividerDark,
                                            ]}
                                        />
                                        <Grid item sm={12} css={classes.quoteAuthorSectionMargin}>
                                            <Text
                                                variant='body1'
                                                color={sectionData.darkMode ? 'inversePrimary' : 'primary'}
                                                data-testid='QuoteAuthorInfo'
                                            >
                                                <span css={classes.quoteAuthorName}>{testimonial.testimonialName}</span>
                                                <span css={classes.quoteVerticalDivider}> | </span>
                                                <span css={classes.quoteAuthorTitle}>
                                                    {testimonial.testimonialTitle}
                                                </span>
                                            </Text>
                                        </Grid>
                                        {sectionData.testimonials && testimonial.logoImagePath ? (
                                            <Grid item sm={12} css={classes.quoteAuthorSectionMargin}>
                                                <Picture
                                                    imagePath={testimonial.logoImagePath}
                                                    alt={testimonial.logoImageAlt}
                                                    lazyLoad={lazyLoad}
                                                    css={[
                                                        classes.testimonialLogoImage,
                                                        imageMode
                                                            ? classes.testimonialLogoImageAlignLeft
                                                            : classes.testimonialLogoImageAlignCenter,
                                                    ]}
                                                    data-testid='TestimonialLogo'
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </LazyLoadContainer>
                        );
                    })}
                </SwipeableViews>
                {showArrows ? (
                    <button
                        css={[classes.chevronIcon, activeStep === maxSteps - 1 ? classes.grayIcon : '']}
                        onClick={() => {
                            handleStepChange(activeStep + 1);
                        }}
                        disabled={activeStep === maxSteps - 1}
                        aria-label='go to next slide'
                    >
                        <RightChevronIcon />
                    </button>
                ) : null}
            </Grid>
            <div>
                <CarouselDotTabs maxDots={maxSteps} activeDot={activeStep} onChangeSlide={handleStepChange} />
            </div>
        </SectionContainer>
    );
}
