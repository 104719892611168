import { breakpoints } from '@web-for-marketing/react-ui';

export const scrollingBannerStyles = {
    scroll: {
        position: 'relative',
        width: '100%',
        '& > div': {
            width: '100%',
        },
    },
    marginRemove: {
        maxWidth: 'unset',
        margin: '0 auto',
        width: '100%',
        userSelect: 'auto',
        '-webkit-user-select': 'auto',
        '& div': {
            height: '100%',
            alignSelf: 'center',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            maxWidth: 'unset',
            margin: '0 auto',
            width: '100%',
            userSelect: 'auto',
            '-webkit-user-select': 'auto',
            '& div': {
                height: '100%',
                alignSelf: 'center',
            },
        },
    },
    leftImageContainer: {
        overflow: 'hidden',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        zIndex: -1,
    },
    leftImageGradient: {
        padding: 0,
        '&::after': {
            content: '""',
            display: 'block',
            top: 0,
            right: 0,
            zIndex: 1,
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
    },
    fullImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain !important' as 'contain',
        objectPosition: 'center',
        display: 'block',
        [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none',
        },
    },
    gridContainer: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        position: 'relative',
        display: 'flex !important',
        '&:focus': {
            outline: 'none',
        },
        [`@media (max-width: ${breakpoints.md})`]: {
            flexDirection: 'column',
        },
    },
    leftImage: {
        [`@media (max-width: ${breakpoints.md})`]: {
            float: 'right',
            height: '100%',
        },
    },
    link: {
        [`@media (max-width: ${breakpoints.md})`]: {
            width: '100%',
            marginTop: '1rem',
            '& > button': {
                padding: '1rem 0',
            },
        },
    },
    linkContainer: {
        [`@media (max-width: ${breakpoints.md})`]: {
            alignItems: 'flex-start',
            marginTop: '2rem',
        },
    },
} as const;
